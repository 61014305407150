import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { BsWhatsapp } from 'react-icons/bs';
import { NavbarLogged, DefaultModal } from '../components';
import { requestSignOut } from '../actions/auth';
import Sidebar from './sidebar';
import baseColors from '../assets/stylesheets/base_colors';

export const LoggedLayout = ({ component: Component, ...rest }) => {
  const {
    ongoingRequest: { getSettings },
    unreadChallenges,
    unreadNews,
    settings: { menuType, whatsappNumber, imageModal }
  } = useSelector(state => state.utils);
  const { user } = useSelector(state => state.auth);
  const [toggled, setToggled] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const dispatch = useDispatch();

  const handleToggleSidebar = () => {
    setToggled(!toggled);
  };
  document.body.style.paddingTop = '60px';
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <>
            {menuType === 1 ? (
              <NavbarLogged
                menu={!getSettings}
                unreadChallenges={unreadChallenges}
                unreadNews={unreadNews}
                handleToggleSidebar={handleToggleSidebar}
              />
            ) : (
              <NavbarLogged handleToggleSidebar={handleToggleSidebar} />
            )}
            <div className="nav-container">
              {!getSettings && (
                <Sidebar
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                  onSignOut={() => dispatch(requestSignOut())}
                  unreadChallenges={unreadChallenges}
                  unreadNews={unreadNews}
                  className={menuType === 1 ? 'hidden-sidebar' : ''}
                />
              )}
              <Container
                fluid
                className={`layout-container ${baseColors.classNameTitle}`}
              >
                {whatsappNumber > 0 && (
                  <div className="btn-wsp">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={`https://api.whatsapp.com/send?phone=${whatsappNumber}`}
                    >
                      <BsWhatsapp className="wsp-icon" />
                    </a>
                  </div>
                )}
                <div style={{ minHeight: 'calc(100vh)' }}>
                  <Component {...props} />
                </div>
              </Container>
              {user?.sign_in_count <= 1 && imageModal && (
                <DefaultModal
                  size="lg"
                  body={
                    <div className="d-flex justify-content-center align-items-center min-h-modal">
                      <img
                        className="img-modal"
                        src={imageModal}
                        alt="imagen modal"
                      />
                    </div>
                  }
                  show={showModal}
                  handleClose={() => setShowModal(false)}
                  handleConfirm={() => setShowModal(false)}
                  titleBtnSave="Aceptar"
                  variantBtnSave="primary"
                />
              )}
            </div>
          </>
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export const NonLoggedLayout = ({ component: Component, ...rest }) => {
  document.body.style.paddingTop = '0%';
  const settings = JSON.parse(localStorage.getItem('settings'));
  const redirectToProfile = settings?.redirect_to_profile;

  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <Redirect
            to={{
              pathname: redirectToProfile === 1 ? '/profile' : '/home',
              state: { from: props.location }
            }}
          />
        ) : (
          <>
            <div>
              <Container fluid style={{ padding: 0, height: 'auto' }}>
                <Component {...props} />
              </Container>
            </div>
          </>
        )
      }
    />
  );
};

export const PublicLayout = ({ component: Component, ...rest }) => {
  const {
    ongoingRequest: { getSettings },
    unreadChallenges,
    unreadNews,
    settings: { menuType }
  } = useSelector(state => state.utils);
  const [toggled, setToggled] = useState(false);
  const dispatch = useDispatch();

  const handleToggleSidebar = () => {
    setToggled(!toggled);
  };

  document.body.style.paddingTop = '0%';

  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <>
            {menuType === 1 ? (
              <NavbarLogged
                menu={!getSettings}
                unreadChallenges={unreadChallenges}
                unreadNews={unreadNews}
                handleToggleSidebar={handleToggleSidebar}
              />
            ) : (
              <NavbarLogged handleToggleSidebar={handleToggleSidebar} />
            )}
            <div className="nav-container public">
              {!getSettings && (
                <Sidebar
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                  onSignOut={() => dispatch(requestSignOut())}
                  unreadChallenges={unreadChallenges}
                  unreadNews={unreadNews}
                  className={menuType === 1 ? 'hidden-sidebar' : ''}
                />
              )}
              <Container fluid>
                <Component {...props} />
              </Container>
            </div>
          </>
        ) : (
          <>
            <div className="nav-container">
              <Container fluid>
                <Component {...props} />
              </Container>
            </div>
          </>
        )
      }
    />
  );
};
