const primary = '#2b2b2b';
const secondary = '#e11672';
const white = '#ffffff';

const baseColors = {
  primary,
  secondary,
  red: '#dc3545',
  primaryBg: white,

  // datatable
  bgDatatable: white,
  colorDatatable: 'null',

  // select
  bgSelect: white,
  bgMultiValueSelect: secondary,
  hoverBgMultiValueRemoveSelect: secondary,
  colorMultiValueSelect: primary,
  hoverTextMultiValueRemoveSelect: white,

  // text points product
  colorTextPointsProducts: primary,

  // login logo size
  logoHeight: 360,
  columnMd: 3,
  classLogo: 'logo-xl',

  // text color login (className)
  textColorLogin: 'text-white',
  classNameBtnLogin: 'btn-secondary',

  // sidebar style (className)
  sidebarStyle: 'sidebar-white',

  // navbar point (className)
  badgePoints: 'custom-badge',

  // home style (className)
  boxColorMyPoints: 'bg-color-my-points',

  // titles (className)
  classNameTitle: 'custom-title',

  gray100: '#f8f9fa',
  gray200: '#e9ecef',
  gray300: '#dee2e6',
  gray400: '#ced4da',
  gray500: '#adb5bd',
  gray600: '#6c757d',
  gray700: '#495057',
  gray800: '#343a40',
  gray900: '#282828'
};

export default baseColors;
